import authClient from '@/authClient';
import uicomponents from '@/components/ui';
import { getMainRoute } from '@/router';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import { createApp } from 'vue';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import 'vuetify/styles';
import config from '../config.js';
import App from './App.vue';
import createRouter, { isRouteGranted } from './router';
import store from './store';

import 'primeicons/primeicons.css';
import { FilterMatchMode } from 'primevue/api';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
});
const renderApp = () => {
  const router = createRouter(store);
  const app = createApp(App);
  uicomponents.forEach(component => {
    app.component(component.name, component);
  });

  // vueapp.config.globalProperties.$api = api;
  app
    .use(store)
    .use(router)
    .use(vuetify)
    .use(PrimeVue, {
      locale: {
        startsWith: 'Fängt an mit',
        contains: 'Beinhaltet',
        notContains: 'Beinhaltet nicht',
        endsWith: 'Endet mit',
        equals: 'Ist gleich',
        notEquals: 'Ist nicht gleich',
        noFilter: 'kein Filter',
        lt: 'Ist kleiner',
        lte: 'Ist gleich oder kleiner',
        gt: 'Ist größer als',
        gte: 'Ist gleich oder größer',
        dateIs: 'Datum ist',
        dateIsNot: 'Datum ist nicht',
        dateBefore: 'Datum ist vor',
        dateAfter: 'Datum ist nach',
        custom: 'Benutzerdefiniert',
        apply: 'Übernehmen',
        matchAll: 'Übereinstimmung mit allen',
        matchAny: 'Übereinstimmung mit einigen',
        addRule: 'Regel hinzufügen',
        removeRule: 'Regel entfernen',
        accept: 'Ja',
        reject: 'Nein',
        choose: 'Auswählen',
        upload: 'Upload',
        cancel: 'Abbruch',
        dayNames: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mär',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dez',
        ],
        today: 'Heute',
        clear: 'Löschen',
        weekHeader: 'Wo',
        firstDayOfWeek: 1,
        dateFormat: 'dd.mm.yy',
        weak: 'Schwach',
        medium: 'Mittel',
        strong: 'Stark',
        passwordPrompt: 'Passwort eingeben',
        emptyFilterMessage: 'Keine Einträge gefunden',
        emptyMessage: 'Keine Einträge gefunden',
        aria: {
          trueLabel: 'Richtig',
          falseLabel: 'Falsch',
          nullLabel: 'Nicht selektiert',
          pageLabel: 'Seite',
          firstPageLabel: 'Erste Seite',
          lastPageLabel: 'Letzte Seite',
          nextPageLabel: 'Nächste Seite',
          previousPageLabel: 'Vorherige Seite',
        },
      },
      filterMatchModeOptions: {
        string: [
          FilterMatchMode.STARTS_WITH,
          FilterMatchMode.ENDS_WITH,
          FilterMatchMode.CONTAINS,
          FilterMatchMode.NOT_CONTAINS,
          FilterMatchMode.EQUALS,
          FilterMatchMode.NOT_EQUALS,
        ],
        number: [
          FilterMatchMode.EQUALS,
          FilterMatchMode.NOT_EQUALS,
        ],
        entity: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
        enum: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
        date: [
          FilterMatchMode.EQUALS,
          FilterMatchMode.NOT_EQUALS,
          FilterMatchMode.LESS_THAN,
          FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
          FilterMatchMode.GREATER_THAN,
          FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        ],
      },
    })
    .use(ConfirmationService)
    .use(ToastService)
    .mount('#app');

  if (config.isAuthEnabled) {
    router.beforeEach((to, from, next) => {

      if (isRouteGranted(to, store)) {

        next();
      } else {
        const mainRoute = getMainRoute(store.state.authInfo.roles);

        if (mainRoute != null) {
          next({ name: mainRoute.name });
        } else {
          next({ name: 'error:401' });
        }
      }
    });
  }
};

if (config.isAuthEnabled) {

  authClient.login(keycloak => {
    renderApp();
  }, store);
} else {
  renderApp();
}
