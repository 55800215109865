<template>
  <Calendar
    :class="class"
    view="month"
    dateFormat="mm.yy"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    showIcon
    :minDate="minDate"
    :maxDate="maxDate"
    :selectOtherMonths="false"
    iconDisplay="input"
    :manualInput="false"
  />
</template>

<script>
import Calendar from 'primevue/calendar';

export default {
  name: 'UiVueMonthPicker',

  components: {
    Calendar,
  },
  props: {
    class: {
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      required: false,
    },
    manualInput: {
      type: Boolean,
      required: false,
      default: true,
    },
    minDate: {
      type: Date,
      required: false,
    },
    maxDate: {
      type: Date,
      required: false,
    },
  },
};
</script>

<style>
.calendar {
  border-radius: 0 !important;
  padding: 15px !important;
  font-family: 'Roboto Condensed', sans-serif !important;
  border: 1px solid rgb(180, 180, 180) !important;
  color: #000 !important;
}

.calendar:hover,
.calendar:focus {
  border: 1px solid rgb(118, 118, 118) !important;
}

.error,
.error:hover,
.error:focus {
  border: 1px solid #b00020 !important;
}

.disabled,
.disabled:hover,
.disabled:focus {
  background-color: rgb(243, 243, 243) !important;
  border: 1px solid rgb(180, 180, 180) !important;
  cursor: not-allowed;
}
</style>
